import * as React from "react";
import PropTypes from 'prop-types';

const ImageFieldCustom = ({ source, record = {} }) => {
    if (record[source]?.url) return (<img style={{width: 'auto', height: 400}} src={process.env.REACT_APP_API + record[source].url} alt="" />)
    else return (<>
    </>)
};

ImageFieldCustom.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default ImageFieldCustom;
