import React, { cloneElement, useState } from "react";
import {
    BooleanField,
    BooleanInput,
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    Filter,
    FunctionField,
    List,
    NumberField,
    ReferenceInput,
    SelectField,
    SelectInput,
    ShowButton,
    TextField,
    TextInput,
    TopToolbar, useListContext,
} from 'react-admin';
import { IconButton, Snackbar, Box, Typography, Button } from '@material-ui/core';
import { Mail, Person, Send } from '@material-ui/icons';
import { ImportButton } from "react-admin-import-csv";
import MuiAlert from '@material-ui/lab/Alert';
import { userStyles } from "../../styles/Styles";
import { sendRequest } from "../../Services";

export const UserIcon = Person;

const UserFilters = props => {
    const classes = userStyles();
    return (
        <Filter {...props}>
            <TextInput label="First Name" source="firstname_contains" resettable alwaysOn />
            <TextInput label="Last Name" source="lastname_contains" resettable alwaysOn />
            <SelectInput source="position" choices={[
                { id: 'G', name: 'Goalkeeper' },
                { id: 'D', name: 'Defender' },
                { id: 'M', name: 'Midfielder' },
                { id: 'F', name: 'Striker' },
                { id: 'C', name: 'Coach' },
                { id: 'J', name: 'Journalist' },
            ]} className={classes.field} />
            <TextInput label="Email" source="email_contains" resettable />
            <TextInput label="Phone" source="phoneNumber_contains" resettable />
            <ReferenceInput label="Club" reference="clubs" source="club">
                <SelectInput source="club" allowEmpty />
            </ReferenceInput>
            <BooleanInput className={classes.switch} label="Voted" source="voted" />
            <BooleanInput className={classes.switch} label="Nomination" source="nomination" />
            {/* <BooleanInput className={classes.switch} label="Journalist" source="isJournalist" alwaysOn/> */}
            <BooleanInput className={classes.switch} label="Empty Email" source="email_null" />
            <BooleanInput className={classes.switch} label="Empty Phone" source="phoneNumber_null" />
        </Filter>
    );
}

const ListActions = props => {
    const {
        currentSort,
        className,
        resource,
        filters,
        displayedFilters,
        exporter,
        filterValues,
        permanentFilter,
        hasCreate,
        basePath,
        selectedIds,
        onUnselectItems,
        showFilter,
        maxResults,
        total,
        ...rest
    } = props;
    return (
        <TopToolbar className={className}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <ImportButton {...props} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
                maxResults={10000}
            />
        </TopToolbar>
    );
};

export const Empty = () => {
    const listContext = useListContext();
    const classes = userStyles();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph className={classes.emptyList}>
                No users
            </Typography>
            <Typography variant="body1" className={classes.emptyList}>
                Create one or import from a file
            </Typography>
            <CreateButton basePath={listContext.basePath} />
            <ImportButton {...listContext} />
        </Box>
    );
}


export const UserList = (props) => {
    const classes = userStyles();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    const handleClose = (snackbar) => {
        snackbar === 'success' ? setSuccess(false) : setError(false);
    }

    const _sendRequest = (action, id) => {
        sendRequest(`${process.env.REACT_APP_API}/players/${action}/${id}`, { method: 'POST' })
            .then(res => {
                setMessage('Message sent!');
                setSuccess(true);
            })
            .catch(err => {
                setMessage('Message failed, check logs!');
                setError(true);
            });
    }
    return (
        <>
            <List {...props}
                filters={<UserFilters />}
                bulkActionButtons={false}
                actions={<ListActions />}
                empty={<Empty />}
            >
                <Datagrid>
                    <TextField source="firstname" />
                    <TextField source="lastname" />
                    <TextField source="club.name" label="Club" />
                    <TextField source="email" />
                    <TextField source="phoneNumber" />
                    <SelectField source="position" choices={[
                        { id: 'G', name: 'Goalkeeper' },
                        { id: 'D', name: 'Defender' },
                        { id: 'M', name: 'Midfielder' },
                        { id: 'F', name: 'Striker' },
                        { id: 'C', name: 'Coach' },
                        { id: 'J', name: 'Journalist' },
                    ]} />
                    <TextField source="birth_year" />
                    <TextField source="stat.spins_index" label="Spins index" />
                    <TextField source="stat.instat_index" label="Instat index" />
                    <BooleanField source="voted" />
                    <BooleanField source="nomination" />
                    <FunctionField
                        label="Send Email"
                        render={record => (
                            <IconButton color="primary" component="span"
                                onClick={() => _sendRequest('sendEmail', record.id)}>
                                <Mail />
                            </IconButton>
                        )}
                    />
                    <FunctionField
                        label="Send SMS"
                        render={record => (
                            <IconButton color="primary" component="span"
                                onClick={() => _sendRequest('sendSms', record.id)}>
                                <Send />
                            </IconButton>
                        )}
                    />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            </List>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={success}
                onClose={() => handleClose('success')}
                autoHideDuration={4000}
            >
                <MuiAlert elevation={6} variant="filled" onClose={() => handleClose('success')} severity="success">
                    {message}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={error}
                onClose={() => handleClose('error')}
                autoHideDuration={4000}
            >
                <MuiAlert elevation={6} variant="filled" onClose={() => handleClose('error')} severity="error">
                    {message}
                </MuiAlert>
            </Snackbar>
        </>);
}
