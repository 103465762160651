import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    BooleanField,
    List,
    TextInput,
    Edit,
    SimpleForm,
    BooleanInput,
    Create,
    Toolbar,
    SaveButton
} from 'react-admin';
import { DateInput, DateTimeInput } from './DateInput'

const UserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const PeriodTitle = ({ record }) => {
    return <span>Period {record ? `"${record.name}"` : ''}</span>;
}

export const PeriodList = props => (
    <List {...props} bulkActionButtons={false} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <DateField source="from" locales="sl-SL" />
            <DateField source="to" locales="sl-SL" />
            <BooleanField source="active" />
            <DateField source="vote_from" showTime locales="sl-SL" />
            <DateField source="vote_to" showTime locales="sl-SL" />
        </Datagrid>
    </List>
);

export const PeriodEdit = props => (
    <Edit {...props} undoable={false} title={<PeriodTitle />}>
        <SimpleForm toolbar={<UserEditToolbar />}>
            <TextInput source="name" />
            <DateInput source="from" label="From" />
            <DateInput source="to" label="To" />
            <BooleanInput source="active" />
            <DateTimeInput source="vote_from" label="Vote from" />
            <DateTimeInput source="vote_to" label="Vote to" />
        </SimpleForm>
    </Edit>
);

export const PeriodCreate = props => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="name" />
            <DateInput source="from" label="From" />
            <DateInput source="to" label="To" />
            <BooleanInput source="active" />
            <DateTimeInput source="vote_from" label="Vote from" />
            <DateTimeInput source="vote_to" label="Vote to" />
        </SimpleForm>
    </Create>
);