import React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    List,
    SelectInput,
    TextField,
    TextInput,
    RichTextField,
    ImageField
} from 'react-admin';

const LogFilters = props => (
    <Filter {...props}>
        <TextInput label="First Name" source="user.firstname" resettable alwaysOn />
        <TextInput label="Last Name" source="user.lastname" resettable alwaysOn />
        <SelectInput source="logType" choices={[
            { id: 'BulkEmail', name: 'Bulk email' },
            { id: 'Email', name: 'Email' },
            { id: 'BulkSMS', name: 'Bulk SMS' },
            { id: 'SMS', name: 'SMS' },
            { id: 'System', name: 'System' },
        ]} alwaysOn />
        <SelectInput source="status" choices={[
            { id: 'success', name: 'Success' },
            { id: 'error', name: 'Error' },
            { id: 'info', name: 'Info' },
        ]} alwaysOn />
        {/* <DateInput source="createdAt"/> */}
    </Filter>
);

const PreviewImage = ({ record, source }) => {
    let src = ''
    if (record?.logo) {
        if (record?.logo?.formats?.thumbnail?.url) src = process.env.REACT_APP_API + record.logo.formats.thumbnail.url
        else src = process.env.REACT_APP_API + record.logo.url
    }
    return <img src={src} alt=" " style={{ width: 21, height: 21 }} />
}


export const ClubList = props => {
    return (
        <List {...props}
            //filters={<LogFilters />}
            exporter={false}
            bulkActionButtons={false}>
            <Datagrid isRowSelectable={record => false}>
                <TextField source="name" />
                <TextField source="instat_id" />
                <PreviewImage source="logo" />
            </Datagrid>
        </List>
    );
}
