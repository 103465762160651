/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
    KeyboardDateTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { useField } from 'react-final-form';
import moment from 'moment';

export function DateInput({ source, label }) {

    const { input, meta } = useField(source);
    const [selectedDate, handleDateChange] = useState(input.value ? input.value : moment());


    React.useEffect(() => {
        if (selectedDate) {
            input.onChange(moment(selectedDate).format('YYYY-MM-DD'))
        }
    }, [selectedDate])


    return (<>
        <KeyboardDatePicker value={selectedDate} onChange={handleDateChange} format="DD/MM/YYYY" label={label} inputVariant="filled" margin="dense" />
    </>
    );
}

export function DateTimeInput({ source, label }) {

    const { input, meta } = useField(source);
    const [selectedDate, handleDateChange] = useState(input.value ? input.value : moment());

    React.useEffect(() => {
        if (selectedDate) {
            input.onChange(moment(selectedDate).format('YYYY-MM-DD HH:mmZZ'))
        }
    }, [selectedDate])


    return (<>
        <KeyboardDateTimePicker value={selectedDate} onChange={handleDateChange} format="DD/MM/YYYY HH:mm" label={label} inputVariant="filled" margin="dense"/>
    </>
    );
}

