import * as React from "react";
import PropTypes from 'prop-types';
import { getResult } from '../../Services'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const Results = ({ source, record = {}, classes }) => {
    const ids = record[source]
    const [results, setResults] = React.useState([])
    const [selectedPeriod, setSelectedPeriod] = React.useState(0)

    React.useEffect(() => {
        if (ids[0]) {
            for (let index = 0; index < ids.length; index++) {
                getResult(ids[index]).then(res => setResults(prevState => {
                    const resArry = [res]
                    const newState = [...prevState, ...resArry]
                    return newState
                }))
            }
        }
    }, [])

    if (results && results[0]) return (<>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedPeriod}
            onChange={e => setSelectedPeriod(e.target.value)}
        >
            {results.map((menuitem, index) => <MenuItem key={index} value={index}>{menuitem.period.name}</MenuItem>)}

        </Select>
        <div className={classes.resultsItemHolder}>
            <TextField value={results[selectedPeriod].public_votes} disabled label="Public votes" className={classes.resultsItem} />
            <TextField value={results[selectedPeriod].internal_votes} disabled label="Internal votes" className={classes.resultsItem} />
            <TextField value={results[selectedPeriod].journalist_votes} disabled label="Journalist votes" className={classes.resultsItem} />
            <TextField value={results[selectedPeriod].coach_votes} disabled label="Coach votes" className={classes.resultsItem} />
            <TextField value={results[selectedPeriod].best_public} disabled label="Best public" className={classes.resultsItem} />
            <TextField value={results[selectedPeriod].best_internal} disabled label="Best internal" className={classes.resultsItem} />
            <TextField value={results[selectedPeriod].best_coach} disabled label="Best coach" className={classes.resultsItem} />
            <TextField value={results[selectedPeriod].best_journalist} disabled label="Best journalist" className={classes.resultsItem} />
        </div>

    </>
    )
    else return (<></>)
};

Results.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default Results;
