import React, {useState} from "react";
import {changePassword} from "../../Services";
import {profileStyles} from "../../styles/Styles";
import {
    Button,
    Card,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Snackbar,
    Typography
} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import {useHistory} from "react-router";

const ChangePassword = ({staticContext, ...props}) => {
    const [oldPassword, setOldPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showRepeat, setShowRepeat] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    const history = useHistory();

    const handleClose = (snackbar) => {
        snackbar === 'success' ? setSuccess(false) : setError(false);
    }

    const handleSubmit = async () => {
        if (oldPassword.length === 0 || newPassword.length === 0) {
            setError(true);
            setMessage('All fields are required!');
            return;
        }
        try {
            const res = await changePassword(oldPassword, newPassword);
            setSuccess(true);
            setMessage(res);
            history.push('/');
        } catch (e) {
            setError(true);
            setMessage(e.message);
        }
    }

    const classes = profileStyles();
    return (
        <>
            <Card className={classes.card}>
                <Typography variant="h5">Change Password</Typography>
                <form onSubmit={event => event.preventDefault()} className={classes.form}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="standard-adornment-password">Old Password</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            autoFocus
                            required
                            fullWidth
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showRepeat ? 'text' : 'password'}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            autoFocus
                            required
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowRepeat(!showRepeat)}
                                    >
                                        {showRepeat ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </FormControl>
                </form>
            </Card>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={success}
                onClose={() => handleClose('success')}
                autoHideDuration={4000}
            >
                <MuiAlert elevation={6} variant="filled" onClose={() => handleClose('success')} severity="success">
                    {message}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={error}
                onClose={() => handleClose('error')}
                autoHideDuration={4000}
            >
                <MuiAlert elevation={6} variant="filled" onClose={() => handleClose('error')} severity="error">
                    {message}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default ChangePassword;
