import React from 'react';
import { HowToVote } from '@material-ui/icons';
import {
    Datagrid,
    DateField,
    Filter,
    FunctionField,
    List,
    ReferenceInput,
    SelectInput,
    TextInput,
    TextField,
} from 'react-admin';
import { Tooltip, Typography, Zoom } from '@material-ui/core';
import { voteStyles } from '../styles/Styles'

export const VoteIcon = HowToVote;

const VoteFilters = props => (
    <Filter {...props}>
        <ReferenceInput label="Period" reference="periods" source="period" alwaysOn allowEmpty={false}>
            <SelectInput source="name" optionText="name" allowEmpty={false} defaultChoice />
        </ReferenceInput>
        <TextInput label="First Name" source="firstname" resettable alwaysOn />
        <TextInput label="Last Name" source="lastname" resettable alwaysOn />
        <ReferenceInput label="Club" reference="clubs" source="club">
            <SelectInput source="club" allowEmpty />
        </ReferenceInput>
        <SelectInput source="audience" choices={[
            { id: 'public', name: 'Public' },
            { id: 'internal', name: 'Player' },
            { id: 'journalist', name: 'Journalist' },
            { id: 'coach', name: 'Coach' },

        ]} alwaysOn />
        {/* <DateInput source="created_at" /> */}
    </Filter>
);

export const VoteList = props => {
    const classes = voteStyles();
    return (
        <List {...props}
            bulkActionButtons={false}
            filters={<VoteFilters />}
            sort={{ field: 'created_at', order: 'DESC' }}
        >
            <Datagrid rowClick="edit">
                <FunctionField label="User" render={record => {
                    return !record.from ? record.ip_address :
                        `${record?.from?.firstname} ${record?.from?.lastname} [${record?.from?.position}]`
                }} />
                <FunctionField
                    label="Top 11"
                    render={record => {
                        let tooltipText = "";
                        let goalKeeper = "[G] ";
                        let defenders = "[D] ";
                        let midfielders = "[M] ";
                        let strikers = "[F] ";
                        record.top_11.forEach((choice) => {
                            switch (choice.position) {
                                case 'G':
                                    goalKeeper += `${choice.firstname} ${choice.lastname} `;
                                    break;
                                case 'D':
                                    defenders += `${choice.firstname} ${choice.lastname}, `;
                                    break;
                                case 'M':
                                    midfielders += `${choice.firstname} ${choice.lastname}, `;
                                    break;
                                case 'F':
                                    strikers += `${choice.firstname} ${choice.lastname}, `;
                                    break;
                            }
                        })
                        tooltipText = goalKeeper + "\n\n" +
                            defenders.substring(0, defenders.length - 2) + "\n\n" +
                            midfielders.substring(0, midfielders.length - 2) + "\n\n" +
                            strikers.substring(0, strikers.length - 2) + "\n";
                        const text = <pre className={classes.formation}>{tooltipText}</pre>;
                        return (<Tooltip
                            title={text}
                            TransitionComponent={Zoom}
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                        ><Typography style={{ width: '100%' }}>Choices</Typography>
                        </Tooltip>)
                    }}
                />
                <FunctionField
                    label="Best Player"
                    render={record => {
                        const user = record.best_player;
                        const bestPlayer = user.firstname + ' ' + user.lastname;
                        return <Typography>{bestPlayer}</Typography>
                    }}
                />
                <FunctionField
                    label="Best Coach"
                    render={record => {
                        const user = record.best_coach;
                        const bestCoach = user.firstname + ' ' + user.lastname;
                        return <Typography>{bestCoach}</Typography>
                    }}
                />
                <TextField source="period.name" label="Period" />
                <DateField source="created_at" locales="sl-SL" showTime={true} />
            </Datagrid>
        </List>
    );
}
