import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

export const VotesBarChart = (props) => (
    <BarChart
        width={400} height={300} data={props.data}
        margin={{top: 20, right: 30, left: 30, bottom: 5}}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="name" tick={{fill: 'white'}}/>
        <YAxis tick={{fill: 'white'}}/>
        <Tooltip/>
        <Legend/>
        <Bar dataKey="total" stackId="a" fill="#00C49F"/>
        <Bar dataKey="voted" stackId="a" fill="#0088FE"/>
    </BarChart>
);
