import {
    BooleanInput,
    Edit,
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    ImageInput,
} from "react-admin";
import { Grid } from "@material-ui/core";
import React from "react";
import { userStyles } from "../../styles/Styles";

export const UserTitle = ({ record }) => {
    return <span>User {record ? `"${record.firstname} ${record.lastname}"` : ''}</span>;
};

const PreviewImage = ({ record, source }) => {

    if (record.url.charAt(0) === '/') {
        record.url = process.env.REACT_APP_API + record.url
    }

    return (

        <div style={{display: 'flex'}}>
            <img style={{ maxHeight: 400 }} className="RaImageField-image-125" src={record.url} alt="" />

        </div>
    )
}

export const UserEdit = (props) => {
    const classes = userStyles();
    return (
        <Edit title={<UserTitle />} {...props} undoable={false}>
            <SimpleForm>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        const role = formData.role;
                        const club = formData.club;

                        return (
                            <>
                                <div className={classes.contentHolder}>
                                    <ImageInput source="image" label="Upload photo" accept="image/*">
                                        <PreviewImage source="url" />
                                    </ImageInput>
                                    <TextInput label="Id" disabled source="id" className={classes.field} />
                                    <TextInput label="First Name" source="firstname" className={classes.field} />
                                    <TextInput label="Last Name" source="lastname" className={classes.field} />
                                    {
                                        role === 'user' &&
                                        <SelectInput label="Position" source="position" choices={[
                                            { id: 'G', name: 'Goalkeeper' },
                                            { id: 'D', name: 'Defender' },
                                            { id: 'M', name: 'Midfielder' },
                                            { id: 'F', name: 'Striker' },
                                        ]} className={classes.field} />
                                    }
                                    <TextInput label="Email" source="email" className={classes.field} />
                                    <TextInput label="Phone Number" source="phoneNumber" className={classes.field} />
                                    <TextInput label="Country" source="country" className={classes.field} />
                                    <NumberInput label="Birth Year" source="birth_year" min={1900}
                                        max={+(new Date()).getFullYear()} className={classes.field} />
                                    <div className={{ ...classes.field, margin: 0 }}>
                                        <Grid component="label" container alignItems="center" spacing={1}
                                            justify="center">
                                            <Grid item>
                                                {/* <BooleanInput
                                                    source="addNewClub"
                                                    label={`Add new ${role === 'journalist' ? 'agency' : 'club'}`}/> */}
                                            </Grid>
                                        </Grid>
                                        {
                                            role === 'journalist' ?
                                                (
                                                    !formData.addNewClub ?
                                                        (<ReferenceInput label="Agency" source="club"
                                                            reference="agencies">
                                                            <SelectInput label="Agency" style={{
                                                                width: '500px',
                                                                margin: 5
                                                            }} />
                                                        </ReferenceInput>) :
                                                        (<TextInput label="Agency" source="club"
                                                            className={classes.field} />)
                                                ) :
                                                (
                                                    !formData.addNewClub ?
                                                        (<ReferenceInput label="Club" source="club"
                                                            reference="clubs" allowEmpty>
                                                            <SelectInput label="Club" style={{
                                                                width: '500px',
                                                                margin: 5
                                                            }} />
                                                        </ReferenceInput>) :
                                                        (<TextInput label="Club" source="club"
                                                            className={classes.field} />)
                                                )
                                        }
                                    </div>
                                    <BooleanInput source="nomination" className={classes.field} />
                                    {role === 'user' &&
                                        <SelectInput
                                            label="Nominated"
                                            source="nomination"
                                            style={{
                                                width: '500px',
                                                margin: 5
                                            }}
                                            choices={[
                                                { id: true, name: 'True' },
                                                { id: false, name: 'False' },
                                            ]}
                                        />}
                                    {role === 'coach' && club !== 'Trener' &&
                                        <SelectInput
                                            label="Nominated"
                                            source="nomination"
                                            style={{
                                                width: '500px',
                                                margin: 5
                                            }}
                                            choices={[
                                                { id: true, name: 'True' },
                                                { id: false, name: 'False' },
                                            ]}
                                        />}
                                </div>

                            </>
                        )
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
}
