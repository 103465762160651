import React from 'react';
import './App.css';
import { Admin, Authenticated, fetchUtils, Resource } from 'react-admin';
import simpleRestProvider from 'ra-strapi-rest';
import { UserIcon, UserList } from './components/user/UserList';
import { UserShow } from "./components/user/UserShow";
import { UserEdit } from "./components/user/UserEdit";
import { UserCreate } from "./components/user/UserCreate";
import { VoteIcon, VoteList } from "./components/VoteList";
import { ClubList } from "./components/ClubList"
import { GroupWork } from '@material-ui/icons';
import { Assessment } from '@material-ui/icons'
import Dashboard from "./components/Dashboard";
import { LogList } from "./components/logs";
import { theme } from "./styles/Styles";
import authProvider from "./authProvider";
import { Route } from 'react-router-dom';
import ChangePassword from "./components/user/ChangePassword";
import { CustomLayout } from "./Layout";
import Cookies from './cookies'
import { PeriodList, PeriodEdit, PeriodCreate } from './components/Periods'
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Rules } from './components/customComponents/Rules';

const settings = {
    headers: {
        'Accept': 'application/json; charset=utf-8',
        'Content-Type': 'application/json; charset=utf-8',
    },
    updateMethod: 'PUT'
};

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = Cookies.getCookie('token')
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

const uploadFields = ["image"];
const dataProvider = simpleRestProvider(process.env.REACT_APP_API, httpClient, uploadFields);

function App() {
    return (
        <div className="App">
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Admin
                    theme={theme}
                    dashboard={Dashboard}
                    dataProvider={dataProvider}
                    authProvider={authProvider}
                    layout={CustomLayout}
                    customRoutes={[
                        <Route
                            key="my-profile"
                            path="/my-profile"
                            render={() =>
                                <Authenticated>
                                    <ChangePassword />
                                </Authenticated>
                            }
                        />,
                        <Route
                            key="rules"
                            path="/rules"
                            render={() =>
                                <Authenticated>
                                    <Rules />
                                </Authenticated>
                            }
                        />
                    ]}
                >
                    <Resource name="players" list={UserList} edit={UserEdit} create={UserCreate} show={UserShow}
                        icon={UserIcon} options={{ label: 'Users' }} />
                    <Resource name="votes" list={VoteList} icon={VoteIcon} options={{ label: 'Votes' }} />
                    <Resource name="periods" list={PeriodList} edit={PeriodEdit} icon={Assessment} create={PeriodCreate} options={{ label: 'Periods' }} />
                    <Resource name="logs" list={LogList} options={{ label: 'Logs' }} />
                    <Resource name="clubs" list={ClubList} icon={GroupWork} options={{ label: 'Clubs' }} />
                    <Resource name="agencies" />
                </Admin>
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default App;
