import React, { forwardRef } from "react";
import { AppBar, Layout, MenuItemLink, UserMenu, getResources } from 'react-admin';
import { Person } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';
import LabelIcon from '@material-ui/icons/Label';

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
    <MenuItemLink
        ref={ref}
        to="/my-profile"
        primaryText="Profile"
        leftIcon={<Person />}
        onClick={onClick}
    />
));

const CustomMenu = props => (
    <UserMenu label='Profile' {...props}
    >
        <ConfigurationMenu />
    </UserMenu>
);

const CustomAppBar = props => (
    <AppBar {...props} userMenu={<CustomMenu />} />
);

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);

    return (
        <div>
            <MenuItemLink
                key={'dashboard'}
                to={`/`}
                primaryText={'Dashboard'}
                leftIcon={<DefaultIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {resources.filter(resource => resource.name !== 'agencies').map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                    }
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            <MenuItemLink
                to="/rules"
                primaryText="Rules"
                leftIcon={<LabelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {isXSmall && logout}
        </div>
    );
};

export const CustomLayout = (props) => <Layout
    {...props}
    appBar={CustomAppBar}
    menu={Menu}
/>;
