import {
    BooleanInput,
    Create,
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField
} from "react-admin";
import { Grid } from "@material-ui/core";
import React from "react";
import { userStyles } from "../../styles/Styles";

export const UserCreate = (props) => {
    const classes = userStyles();
    const validation = (values) => {
        const { role, position, league } = values;
        const errors = {};
        if (role === 'user' && (position === 'C' || position === 'J')) {
            errors.position = ['Invalid position!'];
        }
        if (role === 'coach' && position !== 'C') {
            errors.position = ['Invalid position!'];
        }
        if (role === 'journalist' && position !== 'J') {
            errors.position = ['Invalid position!'];
        }
        if (role === 'journalist' && league !== 'journalists') {
            errors.league = ['Invalid league!'];
        }
        if (role !== 'journalist' && position === 'journalists') {
            errors.league = ['Invalid league!'];
        }
        return errors;
    };
    return (
        <Create {...props} undoable={false}>
            <SimpleForm validate={validation}>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        const { role } = formData;
                        return (
                            <>
                                <div className={classes.contentHolder}>
                                    <ImageInput source="image" label="Upload photo" accept="image/*">
                                        <ImageField source="url" title="" />
                                    </ImageInput>
                                    <TextInput validate={[required()]} label="Official ID" source="official_id"
                                        className={classes.field} />
                                    <TextInput validate={[required()]} label="First Name" source="firstname"
                                        className={classes.field} />
                                    <TextInput validate={[required()]} label="Last Name" source="lastname"
                                        className={classes.field} />
                                    {/* <SelectInput validate={[required()]} label="Role" source="role" choices={[
                                        {id: 'user', name: 'Player'},
                                        {id: 'coach', name: 'Coach'},
                                        {id: 'journalist', name: 'Journalist'},
                                    ]} className={classes.field} allowEmpty={false} defaultValue="user"/> */}

                                    <SelectInput
                                        label="Position" source="position"
                                        choices={[
                                            { id: 'G', name: 'Goalkeeper' },
                                            { id: 'D', name: 'Defender' },
                                            { id: 'M', name: 'Midfielder' },
                                            { id: 'F', name: 'Striker' },
                                            { id: 'C', name: 'Coach' },
                                            { id: 'J', name: 'Journalist' },
                                        ]} className={classes.field} defaultValue="D" allowEmpty={false}
                                    />

                                    {/* {role === 'journalist' &&
                                    <SelectInput
                                        label="Position" source="position"
                                        choices={[
                                            {id: 'J', name: 'Journalist'},
                                        ]} className={classes.field} allowEmpty={false}
                                        defaultValue='J'/>
                                    }
                                    {role === 'coach' &&
                                    <SelectInput
                                        label="Position" source="position"
                                        choices={[
                                            {id: 'C', name: 'Coach'},
                                        ]} className={classes.field} allowEmpty={false}
                                        defaultValue='C'/>
                                    }
                                    {
                                        role !== 'journalist' ?
                                            <SelectInput
                                                label="League" source="league"
                                                choices={[
                                                    {id: 'M1', name: 'M1'},
                                                    // {id: 'M2', name: 'M2'},
                                                    // {id: 'W1', name: 'W1'},
                                                ]} className={classes.field} defaultValue="M1"/> :
                                            <SelectInput
                                                label="League" source="league"
                                                choices={[
                                                    {id: 'journalists', name: 'Journalists'},
                                                ]} className={classes.field} allowEmpty={false}
                                                defaultValue="journalists"/>
                                    } */}
                                    <TextInput validate={[required()]} label="Email" source="email" className={classes.field} />
                                    <TextInput label="Phone Number" source="phoneNumber"
                                        className={classes.field} />
                                    <TextInput label="Country" source="country"
                                        className={classes.field} />
                                    <NumberInput label="Birth Year" source="birth_year"
                                        min={1900}
                                        max={+(new Date()).getFullYear()} className={classes.field} />
                                    <BooleanInput source="nomination" className={classes.field} />
                                    <div className={{ ...classes.field, margin: 0 }}>
                                        <Grid component="label" container alignItems="center" spacing={1}
                                            justify="center">
                                            {/* <Grid item>
                                                <BooleanInput
                                                    source="addNewClub"
                                                    label={`Add new ${role === 'journalist' ? 'agency' : 'club'}`}/>
                                            </Grid> */}
                                        </Grid>
                                        {
                                            role === 'journalist' ?
                                                (
                                                    !formData.addNewClub ?
                                                        (<ReferenceInput label="Agency" source="club"
                                                            reference="agencies">
                                                            <SelectInput validate={[required()]} label="Agency"
                                                                style={{ width: '500px', margin: 5 }} />
                                                        </ReferenceInput>) :
                                                        (<TextInput validate={[required()]} label="Agency" source="club"
                                                            className={classes.field} />)
                                                ) :
                                                (
                                                    !formData.addNewClub ?
                                                        (<ReferenceInput label="Club" source="club"
                                                            reference="clubs" allowEmpty>
                                                            <SelectInput label="Club"
                                                                style={{ width: '500px', margin: 5 }}
                                                                allowEmpty={false} />
                                                        </ReferenceInput>) :
                                                        (<TextInput validate={[required()]} label="Club" source="club"
                                                            className={classes.field} />)
                                                )
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
}
