import React from "react";
import { createMuiTheme } from '@material-ui/core/styles';
import { lightBlue, red } from '@material-ui/core/colors';
import { makeStyles } from "@material-ui/core";

const orange = {
    50: '#fff3e0',
    100: '#ffe0b2',
    200: '#ffcc80',
    300: '#ffb74d',
    400: '#ffa726',
    500: '#ff9800',
    600: '#fb8c00',
    700: '#f57c00',
    800: '#ef6c00',
    900: '#e65100',
    A100: '#ffd180',
    A200: '#ffab40',
    A400: '#f7b03e',
    A700: '#ff6d00',
};

export const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: lightBlue,
        secondary: orange,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
});

export const userStyles = makeStyles((theme) => ({
    simpleShowLayout: {
        display: 'grid',
        width: '90%',
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        height: '100%',
        // justifyContent: 'center',
        margin: 'auto',
        gridTemplateColumns: 'auto auto auto auto auto',
    },
    fieldHolder: {
        width: 'auto',
        margin: '0 12.5px 25px 12.5px',
        backgroundColor: '#4d4d4e',
        borderBottom: '1px solid white',
        borderRadius: '3px 3px 0 0',
        textAlign: 'left',
        paddingLeft: '20px',
        paddingRight: '20px',
        overflow: 'hidden'
    },
    imgHolder: {
        gridColumn: '1 / 6',
        width: 'auto',
        marginBottom: 25,
    },
    switch: {
        color: 'white'
    },
    buttonText: {
        fontSize: '10pt',
        marginLeft: '10px',
    },
    field: {
        width: '500px',
        margin: 5
    },
    contentHolder: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '90%',
        height: '100%',
        margin: 'auto',
    },
    emptyList: {
        color: 'white',
        margin: 15
    },
    resultsItemHolder: {
        display: 'flex',
        marginTop: 20,
        marginBottom: 20
    },
    resultsItem: {
        width: 'auto',
        marginRight: 10
    },
    statItemHolder: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto auto auto',
        marginTop: 20,
        marginBottom: 20
    },

}));

export const dashboardStyles = makeStyles(theme => ({
    button: {
        color: 'white',
        margin: 12
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        margin: 15
    },
    contentHolder: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    icon: {
        marginLeft: 8
    },
    wideCard: {
        width: '800px',
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: '20px'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row'
    }
}));

export const voteStyles = makeStyles((theme) => ({
    tooltip: {
        maxWidth: 1000,
        backgroundColor: '#1b1b1b'
    },
    formation: {
        whiteSpace: 'pre-line',
        textAlign: 'center',
        fontSize: '12pt',
        padding: '2px',
        lineHeight: 1.6,
    },
    tableColumn: {
        width: '300px'
    }
}));

export const profileStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: 8,
        width: 750
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: 450,
        marginTop: 12
    },
    formControl: {
        width: 400,
        marginTop: 8,
        marginBottom: 8
    }
}))
