import { userStyles } from "../../styles/Styles";
import { BooleanField, DateField, EmailField, NumberField, Show, SimpleShowLayout, TextField, ReferenceField, TabbedShowLayout, Tab } from "react-admin";
import React from "react";
import { UserTitle } from "./UserEdit";
import ImageFieldCustom from '../customComponents/ImageField'
import Results from '../customComponents/ResultShow'
import StatShow from '../customComponents/StatShow'

export const UserShow = (props) => {
    const classes = userStyles();
    return (
        <Show title={<UserTitle />} {...props}>
            <TabbedShowLayout
            >
                <Tab label="Profile" contentClassName={classes.simpleShowLayout}>

                    <ImageFieldCustom source='image' label="Photo" className={classes.imgHolder} />
                    <TextField source="id" className={classes.fieldHolder} />
                    <TextField source="official_id" label="Official ID" className={classes.fieldHolder} />
                    <TextField source="firstname" className={classes.fieldHolder} />
                    <TextField source="lastname" className={classes.fieldHolder} />
                    <ReferenceField label="Club" source="club" reference="clubs" className={classes.fieldHolder}>
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="position" className={classes.fieldHolder} />
                    <TextField source="email" className={classes.fieldHolder} />
                    <TextField source="phoneNumber" className={classes.fieldHolder} />
                    <TextField source="birth_year" className={classes.fieldHolder} />
                    <BooleanField source="voted" className={classes.fieldHolder} />

                </Tab>

                <Tab label="Results" contentClassName={classes.simpleShowLayout}>
                    <Results source="results" classes={classes} />
                </Tab>

                <Tab label="Stat" contentClassName={classes.simpleShowLayout}>
                    <StatShow source="stat" classes={classes} />
                </Tab>


            </TabbedShowLayout>
        </Show>
    );
}
