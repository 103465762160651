import axios from 'axios';
import Cookies from './cookies'

// export const clearAllLogs = async () => {
//     try {
//         const res = await sendRequest(`${process.env.REACT_APP_API}/clearLogs`, {
//             method: 'POST',
//         });
//         return 'All logs are cleared!';
//     } catch (error) {
//         throw error;
//     }
// };

export const sendBulkEmail = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/players/sendBulkEmail`, {
            method: 'POST',
        });
        return 'Finished sending mails!';
    } catch (error) {
        throw error;
    }
}

export const sendBulkSMS = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/players/sendSms`, {
            method: 'POST',
        });
        return 'Sending messages initialized!';
    } catch (error) {
        throw error;
    }
}

export const startVoting = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/startVoting`, {
            method: 'POST',
        });
        return 'Voting is now open!';
    } catch (error) {
        throw error;
    }
}

export const closeVoting = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/closeVoting`, {
            method: 'POST',
        });
        return 'Voting is closed!';
    } catch (error) {
        throw error;
    }
}

export const nominate = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/nominate`, {
            method: 'POST',
        });
        return 'Nominations finished!';
    } catch (error) {
        throw error;
    }
}

export const publishResults = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/publishResults`, {
            method: 'POST',
        });
        return 'Results are published!';
    } catch (error) {
        throw error;
    }
}

export const canSendEmail = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/players/canSendEmail`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        return false;
    }
}

export const canSendSms = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/players/canSendSms`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        return false;
    }
}

export const getResult = async (id) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/results/${id}`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        return false;
    }
}

export const getStat = async (id) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/stats/${id}`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        return false;
    }
}

export const getChartsData = async (id) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/results/chartData?period=${id}`, { method: 'GET' });
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const getAppStatus = async () => {
    try {
        const res = await sendRequest(process.env.REACT_APP_API_APP_STATUS, { method: 'GET' });
        return res.data.status;
    } catch (err) {
        throw err;
    }
}

export const getPeriods = async (id) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/periods`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        return false;
    }
}

export const getSMSText = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/messages`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        return false;
    }
}

export const getRules = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/rules`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        return false;
    }
}

export const saveSMSText = async (messages) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/messages`, {
            method: 'PUT',
            data: {
                smsText: messages.smsText,
                emailText: messages.email
            }
        });
        return res.data;
    } catch (error) {
        return false;
    }
}


export const saveRulesText = async (text) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/rules`, {
            method: 'PUT',
            data: {
                glasovanje: text
            }
        });
        return res.data;
    } catch (error) {
        return false;
    }
}

export const changePassword = async (oldPassword, newPassword) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/changePassword`, {
            method: 'POST',
            data: {
                oldPassword, newPassword,
            }
        });
        return 'Password changed successfully!';
    } catch (error) {
        throw new Error('Password can\'t be changed!');
    }
};

export const sendRequest = async (url, options) => {
    const token = Cookies.getCookie('token')
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    };
    try {
        return await axios(url, {
            headers,
            ...options
        });
    } catch (error) {
        if (error.status === 401 || error.status === 403) {
            throw error;
        }
        if (error.response) {
            console.log(error);
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //422 handle to be done
            throw new Error(error.response.data.message);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error.message);
        }
    }
};
