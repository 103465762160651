import React from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FF8042', '#F7B03E'];

export const VotesPieChart = (props) => (
    <PieChart width={400} height={300}>
        <Pie isAnimationActive={true} data={props.data} fill="#8884d8" label>
            {
                props.data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
            }
        </Pie>
        <Tooltip />
        <Legend layout='horizontal' verticalAlign='bottom' />
    </PieChart>
);
