import React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    List,
    SelectInput,
    TextField,
    TextInput,
    RichTextField
} from 'react-admin';
import { voteStyles } from "../styles/Styles";

const LogFilters = props => (
    <Filter {...props}>
        <TextInput label="First Name" source="user.firstname" resettable alwaysOn />
        <TextInput label="Last Name" source="user.lastname" resettable alwaysOn />
        <SelectInput source="logType" choices={[
            { id: 'BulkEmail', name: 'Bulk email' },
            { id: 'Email', name: 'Email' },
            { id: 'BulkSMS', name: 'Bulk SMS' },
            { id: 'SMS', name: 'SMS' },
            { id: 'System', name: 'System' },
        ]} alwaysOn />
        <SelectInput source="status" choices={[
            { id: 'success', name: 'Success' },
            { id: 'error', name: 'Error' },
            { id: 'info', name: 'Info' },
        ]} alwaysOn />
        {/* <DateInput source="createdAt"/> */}
    </Filter>
);

const StackPanel = ({ id, record, resource }) => (
    <div>
        <pre>{record.stack}</pre>
    </div>
);

export const LogList = props => {
    return (
        <List {...props} filters={<LogFilters />}
            //  sort={{field: 'createdAt', order: 'DESC'}}
            bulkActionButtons={false}>
            <Datagrid isRowSelectable={record => false} expand={<StackPanel />}>
                <TextField source="logType" />
                <FunctionField label="User" render={record => {
                    let res = record?.user?.firstname;
                    (record?.user?.lastname && record?.user?.lastname?.length) > 0 ?
                        res += ` ${record?.user?.lastname} [${record?.user?.position}] (${record?.user?.club})` :
                        res += '';
                    if (res === 'undefined') return 'No user'
                    if (!res) return 'No user'
                    else return res;
                }} />
                <TextField source="message" />
                <TextField source="status" />
                {/* <DateField source="createdAt" locales="sl-SL" showTime={true}/> */}
            </Datagrid>
        </List>
    );
}
