import * as React from "react";
import PropTypes from 'prop-types';
import { getStat } from '../../Services'
import TextField from '@material-ui/core/TextField';

const StatShow = ({ source, record = {}, classes }) => {
    const id = record[source]
    const [results, setResults] = React.useState()

    React.useEffect(() => {
        if (id) {
            getStat(id).then(res => setResults(res))
        }
    }, [])

    if (results) {
        const keys = Object.keys(results);
        return (<>
            <div className={classes.statItemHolder}>
                {keys.map((key, index) => {
                    //   console.log(`${key}: ${results[key]}`);
                    const label = key.charAt(0).toUpperCase() + key.slice(1)
                    return (<TextField key={index} value={results[key]} disabled label={label} className={classes.resultsItem} />)
                })}
            </div>
        </>
        )
    }
    else return (<></>)
};

StatShow.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default StatShow;
