import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Card, Snackbar, Typography, CardContent, CardHeader } from '@material-ui/core';
import { Title, useGetList } from 'react-admin';
import { dashboardStyles } from "../styles/Styles";
import { ErrorOutline, Mail, Send } from "@material-ui/icons";
import {
    canSendEmail,
    canSendSms,
    // clearAllLogs,
    closeVoting,
    getAppStatus, getChartsData,
    nominate,
    publishResults,
    sendBulkEmail,
    sendBulkSMS,
    startVoting,
    getPeriods,
    getSMSText,
    saveSMSText,
    getRules
} from "../Services";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { VotesBarChart } from "./StackedBarChart";
import { VotesPieChart } from "./PieChart";
import { useHistory } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const resolveRequest = async (action) => {
    switch (action) {
        case 'startVoting':
            return await startVoting();
        case 'closeVoting':
            return await closeVoting();
        case 'nominate':
            return await nominate();
        case 'publishResults':
            return await publishResults();
        case 'sendBulkSms':
            return await sendBulkSMS();
        case 'sendBulkEmail':
            return await sendBulkEmail();
        // case 'clearAllLogs':
        //     return await clearAllLogs();
    }
}

export default () => {
    const classes = dashboardStyles();

    const [emailEnabled, setEmailEnabled] = useState(false);
    const [smsEnabled, setSmsEnabled] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [appStatus, setAppStatus] = useState('');
    const [dialog, setDialog] = useState(false);
    const [request, setRequest] = useState('');
    const [stackedChart, setStackedChart] = useState([]);
    const [pieChart, setPieChart] = useState([]);
    const [totalVotesCount, setTotalVotesCount] = useState(0);
    const history = useHistory();
    const [periods, setPeriods] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState(0)
    const [messages, setMessages] = useState({
        sms: 'SMS Test',
        email: ''
    })
    const [isLoading, setIsLoading] = useState(true)

    const getStats = () => {
        getPeriods().then(res => {
            const sortedPeriods = res && res[0] && res.sort((a, b) => a - b)
            setPeriods(sortedPeriods)
            setSelectedPeriod(sortedPeriods[0]?.id)
        });
        canSendEmail().then(res => setEmailEnabled(res));
        canSendSms().then(res => setSmsEnabled(res));
        getAppStatus().then(res => setAppStatus(res)).catch(err => {
            setMessage(err.message);
            setError(true);
        });
        getSMSText().then(res => setMessages({
            sms: res.smsText,
            email: res.emailText
        }))
    }

    useEffect(() => {
        if (selectedPeriod) {
            getChartsData(selectedPeriod).then(res => {
                setStackedChart(Object.values(res[0]));
                setPieChart(res[1]);
                setTotalVotesCount(res[2]);
                setIsLoading(false)
            });
        }
    }, [selectedPeriod])

    useEffect(() => getStats(), [])

    const handleClose = (snackbar) => {
        snackbar === 'success' ? setSuccess(false) : setError(false);
    }

    const handleButtonClick = (request) => {
        setRequest(request);
        setDialog(true);
    }

    const _sendRequest = () => {
        resolveRequest(request)
            .then(res => {
                setMessage(res);
                setSuccess(true);
                getStats();
            })
            .catch(err => {
                setMessage('Request failed, check logs!');
                setError(true);
            });
    }

    const handleSMSChange = (e) => {
        setMessages(prevState => ({
            prevState,
            sms: e?.target?.value
        }))
    }

    const handleEmailChange = (e) => {
        setMessages(prevState => ({
            prevState,
            email: e?.target?.value
        }))
    }

    return (
        <div className={classes.contentHolder}>
            <Title title={`Welcome to the administration. Status: ${appStatus}`} />
            <Card className={classes.card}>
                <Button variant="contained" className={classes.button}
                    style={{ backgroundColor: '#2e89ff' }}
                    onClick={() => history.replace('/periods/create')}
                >
                    Create period
                </Button>
                {/* <Button variant="contained" className={classes.button}
                    style={{ backgroundColor: '#149855' }}
                    disabled={appStatus !== 'Published'}
                    onClick={() => {
                        handleButtonClick('startVoting');
                        setMessage('open voting');
                    }}
                >
                    Start Voting
                </Button>
                <Button variant="contained" className={classes.button}
                    style={{ backgroundColor: '#2e89ff' }}
                    onClick={() => {
                        handleButtonClick('nominate');
                        setMessage('open nominations');
                    }}>
                    Nominate
                </Button>
                <Button variant="contained" className={classes.button}
                    style={{ backgroundColor: '#2e89ff' }}
                    onClick={() => {
                        handleButtonClick('closeVoting');
                        setMessage('close voting');
                    }}>
                    Close Voting
                </Button>
                <Button variant="contained" className={classes.button}
                    style={{ backgroundColor: '#2e89ff' }}
                    onClick={() => {
                        handleButtonClick('publishResults');
                        setMessage('publish results');
                    }}>
                    Publish Results
                </Button> */}
            </Card>
            <Card className={classes.card}>
                <Button variant="contained" className={classes.button}
                    style={{ backgroundColor: '#2e89ff' }}
                    disabled={!emailEnabled}
                    onClick={() => {
                        handleButtonClick('sendBulkEmail');
                        setMessage('send email to all users');
                    }}
                >
                    Send Bulk Email
                    <Mail className={classes.icon} />
                </Button>
                <Button variant="contained" className={classes.button}
                    style={{ backgroundColor: '#2e89ff' }}
                    disabled={!smsEnabled}
                    onClick={() => {
                        setSmsEnabled(false);
                        handleButtonClick('sendBulkSms');
                        setMessage('send SMS to all users');
                    }}
                >
                    Send Bulk SMS
                    <Send className={classes.icon} />
                </Button>
                {/* <Button variant="contained" className={classes.button}
                        style={{backgroundColor: '#ff3726'}}
                        onClick={() => {
                            handleButtonClick('clearAllLogs');
                            setMessage('clear ALL logs');
                        }}>
                    Clear ALL Logs
                    <ErrorOutline className={classes.icon}/>
                </Button> */}
            </Card>
            <Card className={`${classes.card} ${classes.wideCard}`}>
                <Typography variant='h5'>
                    Choose period:
                </Typography>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedPeriod}
                    onChange={e => setSelectedPeriod(e.target.value)}
                    style={{ margin: 10, marginLeft: 50, marginRight: 50 }}
                >
                    {periods && periods.map((menuitem, index) => <MenuItem key={index} value={menuitem.id}>{menuitem.name}</MenuItem>)}

                </Select>
                <Typography variant='h5'>
                    Total votes: {totalVotesCount}
                </Typography>
                <CardContent className={classes.cardContent}>
                    {!isLoading ? <><VotesBarChart data={stackedChart} />
                        <VotesPieChart data={pieChart} />
                    </>
                        :
                        <div>Loading...</div>}
                </CardContent>
            </Card>
            <Card className={`${classes.card} ${classes.wideCard}`} style={{ width: '30%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography variant='h5'>
                        SMS text:
                    </Typography>
                    <TextField value={messages.sms} onChange={handleSMSChange} label="" multiline style={{ margin: 10 }} variant="standard" />
                    <Button variant="contained" className={classes.button}
                        style={{ backgroundColor: '#2e89ff', width: 200 }}
                        onClick={() => {
                            saveSMSText(messages).then(res => res && alert('Successfully changed'))
                        }}                >
                        Save SMS text
                    </Button>
                </div>
            </Card>
            <Card className={`${classes.card} ${classes.wideCard}`} style={{ width: '50%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography variant='h5'>
                        Email text:
                    </Typography>
                    <TextField value={messages.email} onChange={handleEmailChange} label="" multiline style={{ margin: 10 }} variant="standard" />
                    <Button variant="contained" className={classes.button}
                        style={{ backgroundColor: '#2e89ff', width: 200 }}
                        onClick={() => {
                            saveSMSText(messages).then(res => res && alert('Successfully changed'))
                        }}                >
                        Save email text
                    </Button>
                </div>
            </Card>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={success}
                onClose={() => handleClose('success')}
                autoHideDuration={4000}
            >
                <MuiAlert elevation={6} variant="filled" onClose={() => handleClose('success')} severity="success">
                    {message}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={error}
                onClose={() => handleClose('error')}
                autoHideDuration={4000}
            >
                <MuiAlert elevation={6} variant="filled" onClose={() => handleClose('error')} severity="error">
                    {message}
                </MuiAlert>
            </Snackbar>
            <Dialog
                open={dialog}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Confirm
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure that you want to {message}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        setDialog(false);
                        _sendRequest();
                    }} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>);
}
