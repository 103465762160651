import { Button, Card, Typography } from '@material-ui/core';
import * as React from "react";
import {
    saveRulesText,
    getRules
} from "../../Services";
import { dashboardStyles } from "../../styles/Styles";
import TextField from '@material-ui/core/TextField';

export const Rules = () => {
    const classes = dashboardStyles();
    const [rules, setRules] = React.useState()

    React.useEffect(() => {
        getRules().then(res => setRules(res.glasovanje))
    }, [])
    return (<Card className={`${classes.card} ${classes.wideCard}`} style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography variant='h5'>
                Rules page:
            </Typography>
            <TextField value={rules} onChange={(e) => setRules(e.target.value)} label="" multiline style={{ margin: 10 }} variant="standard" />
            <Button variant="contained" className={classes.button}
                style={{ backgroundColor: '#2e89ff', width: 200 }}
                onClick={() => {
                    saveRulesText(rules).then(res => res && alert('Successfully changed'))
                }}                >
                Save Rules text
            </Button>
        </div>
    </Card>)
}